import { useEffect, useState } from 'react';
import {
    Outlet,
    Link,
    useNavigate
} from "react-router-dom";

import { useRecoilState, useRecoilValue } from 'recoil';
import { authenticated, access, refresh } from "../../state/atoms/Authenticated";

// import { loggedcreator } from "../../state/atoms/Creator";

import { checkAuth, logout } from '../../state/actions/auth';
import Logo from '../../images/new_logo.png';
import Logo2 from '../../images/new_logo.png';
import Logo3 from '../../images/prymshare2.png';
import Navigation from '../../images/Navigation.png';
import DismissCircle from '../../images/close-new.png';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';


const Menu = ({sidebar, showSidebar}) => {
    let navigate = useNavigate();
    const isAuth = useRecoilValue(authenticated);
    const [authState, setAuthState] = useRecoilState(authenticated);
    const [accessState, setAccessState] = useRecoilState(access);
    const [refreshState, setRefreshState] = useRecoilState(refresh);

    // const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);

    return (
        <div className="bg-[#D2D8C7] pt-36 p-3 z-10 w-full h-screen">
            <div onClick={showSidebar}>
                <Link to='/' className='block m-auto text-5xl text-center mb-7 cursor-pointer font-black tracking-tight text-[#121B00]'>HOME</Link>
                <Link to='/features' className='block m-auto text-5xl text-center mb-7 cursor-pointer font-black tracking-tight text-[#121B00]'>FEATURES</Link>
                <Link to='/pricing' className='block m-auto text-5xl text-center mb-7 cursor-pointer font-black tracking-tight text-[#121B00]'>PRICING</Link>
                <Link to='/feedback' className='block m-auto text-5xl text-center mb-7 cursor-pointer font-black tracking-tight text-[#121B00]'>CONTACT US</Link>
                <Link to='/privacy-policy' className='block m-auto text-5xl text-center mb-9 cursor-pointer font-black tracking-tight text-[#121B00]'>PRIVACY POLICY</Link>
            </div>

            <div className='flex flex-col'>
            {!isAuth ? 
                <>
                <div className='mt-3 text-center w-full'>
                    <Link to='/login' className='block w-full px-6 py-3 text-sm font-medium bg-white border-2 border-neutral-300 rounded-full'>Login</Link>
                </div>  
                <div className='mt-3 text-center w-full'>
                    <Link to='/register' className='block w-full px-6 py-4 text-sm font-medium bg-secondaryy-800 text-white rounded-full'>Join for free</Link>
                </div>
                </>
                :
                <>
                    <div className='mt-3 mb-3 text-center w-full'>
                        <Link to='/me/appearance' className='block w-full px-6 py-4 text-sm font-medium bg-secondaryy-800 text-white rounded-full'>My Profile</Link>
                    </div>
                    <button className='bg-white px-6 py-2 text-black hover:text-white hover:bg-primaryy-400 border-2 border-neutral-200 rounded-full' onClick={() => logout(setAuthState, setAccessState, setRefreshState)}>Logout</button>
                </>
            }
            </div>
        </div>
    )
}

const Navbar = () => {
    const isAuth = useRecoilValue(authenticated);
    const [authState, setAuthState] = useRecoilState(authenticated);
    const [accessState, setAccessState] = useRecoilState(access);
    const [refreshState, setRefreshState] = useRecoilState(refresh);

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        // console.log(isAuth + ' for navigation');
        setSidebar(!sidebar);

    } 



	return (
    <div>
        <div className='fixed w-full p-3 pt-5 px-4 lg:px-7 z-20 drop-shadow-xl animate-fader'>
            <div className='flex flex-row justify-between items-center m-auto px-4 pr-6 py-3 w-full max-w-screen-xl lg:max-w-screen-lg  bg-white rounded-full lg:py-3'>
                <div className='flex justify-between items-center w-7/12'>
                    <div className='w-40 lg:hidden'>
                        <Link className='block' to="/"><img src={Logo3} className='w-full' alt='Prymshare Logo' /></Link>
                    </div>
                    <div className='hidden lg:flex w-2/12'>
                        <Link className='block' to="/"><img src={Logo2} className='w-full' alt='Prymshare Logo' /></Link>
                    </div>
                    <div className='hidden lg:flex flex-row justify-between text-black w-7/12 text-xs items-center font-medium'>
                        <Link to='/features'>Features</Link>
                        <Link to='/pricing'>Pricing</Link>
                        <a href='mailto:prymshare.info@gmail.com'>Contact Us</a>
                        <Link to='/privacy-policy'>Privacy Policy</Link>
                    </div>
                </div>

                {!isAuth ?
                <div className='hidden lg:flex flex-row justify-between text-black w-3/12 text-sm items-center font-medium'>
                    <Link to='/login' className='bg-white px-6 py-2 text-black hover:text-white hover:bg-primaryy-400 border-2 text-xs border-neutral-200 rounded-full'>Login</Link>
                    <Link to='/register' className='bg-secondaryy-800 text-white hover:text-white hover:bg-primaryy-300 px-6 py-2.5 text-xs rounded-full'>Join for Free</Link>
                </div> : 
                <>
                    <div className='hidden lg:flex flex-row justify-between text-black w-3/12 text-xs items-center font-medium'>
                        <Link to='/me/appearance' className='bg-white px-6 py-2 text-black hover:text-white hover:bg-primaryy-400 border-2 border-neutral-200 rounded-full'>My Profile</Link>
                        <button className='bg-white px-6 py-2 text-black hover:text-white hover:bg-primaryy-400 border-2 border-neutral-200 rounded-full' onClick={() => logout(setAuthState, setAccessState, setRefreshState,)}>Logout</button>
                    </div>
                </>
                
                }

                {!sidebar ?
                    <div className='w-6 lg:hidden' onClick={showSidebar}>
                        <img src={Navigation} className='w-full cursor-pointer' alt='Nav' />
                    </div>
                    :
                    <div className='w-7 lg:hidden' onClick={showSidebar}>
                        <img src={DismissCircle} className='w-7/12 cursor-pointer' alt='Dismiss' />
                    </div>
                }
            </div>
        </div>
        {!sidebar ? 
            <div className='animate-faderrout'>
            </div> :
            <div className='animate-fader z-10 transition ease-in-out duration-500 fixed w-full'>
                <Menu sidebar={sidebar} showSidebar={showSidebar}  />
            </div>
        }
    </div>
        
	);
}

export default Navbar;