import Addd from '../../images/add-circle.png';
import Plus from '../../images/plus-circle.png';
import Pro from '../../images/Tag.png';
import Premium from '../../images/premiums.png';
import Gift from '../../images/giftbox.png';
import Xprice from '../../images/x-price.png';
import NLink from '../../images/link1.png';
import Ticket from '../../images/ticket.png';
import { useEffect, useState, useRef } from 'react';
import { useRecoilState } from 'recoil';
import {Link, useParams, useNavigate } from "react-router-dom";

import { loggedcreatordata } from "../../state/atoms/Creator";

import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TECollapse,
} from "tw-elements-react";

import { creatorlinks } from "../../state/atoms/Creator";

import { addCreatorLinks } from '../../state/actions/creator';

import PaywallModal from "./linkButtons/paywallModal.tsx";
import NormalModal from "./linkButtons/normalLink.tsx";
import GiftModal from "./linkButtons/giftModal.tsx";
import TicketsModal from "./linkButtons/ticketsModal.tsx";
import { setFlagsFromString } from 'v8';




function AddBlockBtn({ showGift, setShowGift, availableBlocks, handleAddBlock, showModal, setShowModal }) {
    let navigate = useNavigate();
    const [eliteCreator, setEliteCreator] = useState(false)
    const [impactCreator, setImpactCreator] = useState(false)
    const [eventAddon, setEventAddon] = useState(false)
    const [oneononeAddon, setOneononeAddon] = useState(false)
    const [subaccount, setSubaccount] = useState('')
    const [url, setUrl] = useState('')

    // const [linkType, setLinkType] = useState('normal')
    // const [price, setPrice] = useState(0)

    // const [loading, setLoading] = useState(false)
    // const [loadingPrice, setLoadingPrice] = useState(false)
    const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);

    useEffect(() => {
        setEliteCreator(loggedCreatorData.creator.elite_creator);
        setImpactCreator(loggedCreatorData.creator.impact_creator);
        setEventAddon(loggedCreatorData.creator.ticket_addon)
        setOneononeAddon(loggedCreatorData.creator.one_on_one_addon)
        setSubaccount(loggedCreatorData.creator.subaccount_code)
        // console.log(loggedCreatorData.creator.elite_creator + ' elite-ish')
        // console.log(loggedCreatorData.creator.ticket_addon + ' ticket addon-ish')
        // console.log(loggedCreatorData.creator.subaccount_code)
    }, [loggedCreatorData]);

    const [submittable, setSubmittable] = useState('')


    // const [editLogo, setEditLogo] = useState(null);
    // const [prologo, setPrologo] = useState(null);
	// const [prologoUrl, setPrologoUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);

    
    const [showPaywallModal, setShowPaywallModal] = useState(false);
    const [showNormalModal, setShowNormalModal] = useState(false);
    const [showEvent, setShowEvent] = useState(false);
    

    const addNormalLink = () => {
        // setLinkType('paywalled')

        setShowNormalModal(true);
        setShowModal(false);
    }

    const addPaywall = () => {
        // setLinkType('paywalled')

        setShowPaywallModal(true);
        setShowModal(false);
    }

    const addGift = () => {
        // setLinkType('tipping')

        setShowGift(true);
        setShowModal(false);
    }

    const addEvent = () => {
        // setLinkType('tipping')

        setShowEvent(true);
        setShowModal(false);
    }

    //   const onSubmit = e => {
    //     e.preventDefault();
    //     setLoading(true);
    //     // console.log(selectedOption.value);
    //     let formData = new FormData();
    //     if (prologo != null) {
    //       formData.append('thumb', prologo);
    //     } else if (editLogo == null) {
    //       formData.append('thumb', '');
    //     }
    //     formData.append('title', linktitle);
    //     // formData.append('creator_username', username);
    //     formData.append('url', url);
    //     formData.append('type', linkType);
    //     formData.append('price', price);
       
    
    //     addCreatorLinks(formData, setShowModal, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setUrl);
    //   };

    const noSubAccount = () => {
        alert('Setup your payment settings before proceeding!');
        navigate('/me/payment');
    }
    
    return (
        <div className="add-blocks">
            <div className="add-block-btn" onClick={() => setShowModal(true)}>
                <img src={Plus} />
                <p>Add a link</p>
            </div>


            {/* MODAL FOR ADDING BLOCKS */}
            <TEModal show={showModal} setShow={setShowModal} scrollable className='!bg-white [&_*:not(button)]:!bg-white'>
            <TEModalDialog className={showPaywallModal || showGift ? '!p-3 !blur-sm' : '!p-3'}>
                <TEModalContent className=''>
                <TEModalHeader>
                    {/* <!--Modal title--> */}
                    <h5 className="text-medium font-bold leading-normal text-neutral-800">
                    Add a link
                    </h5>
                    {/* <!--Close button--> */}
                    <button
                    type="button"
                    className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                    aria-label="Close"
                    >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="h-6 w-6"
                    >
                        <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    </button>
                </TEModalHeader>
                {/* <!--Modal body--> */}
                <TEModalBody>                

                    <>
                        {/* <hr className='w-full text-[#D2D8C7] mt-8 mb-8' /> */}

                        <div className='flex flex-col'>
                            {/* <p className='font-bold text-sm '>Do more with your links <span className='ml-2 py-1 px-2 text-xs font-black border-[#00D37F] border-2 rounded-full '>PRO</span></p> */}
                            {/* <p className='font-bold text-sm '>Do more with your links</p> */}
                            
                            <div className='flex items-center p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addNormalLink}>
                                <img src={NLink} className='w-[40px] mr-4 ' />
                                <div className='flex flex-col '>
                                    <h2 className='font-bold text-sm'>Add a simple link</h2>
                                    <p className='font-normal text-xs'>Share any link</p>
                                </div>
                            </div>

                            {
                                subaccount == '' || subaccount == null || subaccount == undefined ? 
                                <div onClick={noSubAccount}>
                                    <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addPaywall}>
                                        <img src={Premium} className='w-[40px] mr-4 ' />
                                        <div className='flex flex-col '>
                                            <h2 className='font-bold text-sm'>Add a paywall</h2>
                                            <p className='font-normal text-xs'>Restrict access to exclusive links</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addGift}>
                                        <img src={Gift} className='w-[40px] mr-4 ' />
                                        <div className='flex flex-col '>
                                            <h2 className='font-bold text-sm'>Get Tipped</h2>
                                            <p className='font-normal text-xs'>Get your fans to show you love</p>
                                        </div>
                                    </div>

                                    <hr className='w-full text-[#D2D8C7] mt-8 mb-8' />

                                    <p className='font-bold text-sm '>Do more with your links <span className='ml-2 py-1 px-2 text-xs font-black border-[#00D37F] border-2 rounded-full '>PRO</span></p>
                                    {/* <p className='font-bold text-sm '>Do more with your links</p> */}

                                    {/* {eliteCreator || eventAddon ?  */}
                                        <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addEvent}>
                                            <img src={Ticket} className='w-[40px] mr-4 ' />
                                            <div className='flex flex-col '>
                                                <h2 className='font-bold text-sm'>Manage Event</h2>
                                                <p className='font-normal text-xs'>Handle your events like a pro!</p>
                                            </div>
                                        </div>
                                        
                                        {/* :
                                        <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer opacity-50' onClick={() => navigate('/pricing')}>
                                            <img src={Ticket} className='w-[40px] mr-4 ' />
                                            <div className='flex flex-col '>
                                                <h2 className='font-bold text-sm'>Manage Event</h2>
                                                <p className='font-normal text-xs'>Handle your events like a pro!</p>
                                            </div>
                                        </div>
                                    } */}
                                    
                                </div>
                                
                                : 
                                <>
                                    <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addPaywall}>
                                        <img src={Premium} className='w-[40px] mr-4 ' />
                                        <div className='flex flex-col '>
                                            <h2 className='font-bold text-sm'>Add a paywall</h2>
                                            <p className='font-normal text-xs'>Restrict access to exclusive links</p>
                                        </div>
                                    </div>
                                    <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addGift}>
                                        <img src={Gift} className='w-[40px] mr-4 ' />
                                        <div className='flex flex-col '>
                                            <h2 className='font-bold text-sm'>Get Tipped</h2>
                                            <p className='font-normal text-xs'>Get your fans to show you love</p>
                                        </div>
                                    </div>

                                    <hr className='w-full text-[#D2D8C7] mt-8 mb-8' />

                                    <p className='font-bold text-sm '>Do more with your links <span className='ml-2 py-1 px-2 text-xs font-black border-[#00D37F] border-2 rounded-full '>PRO</span></p>
                                    {/* <p className='font-bold text-sm '>Do more with your links</p> */}

                                    {/* {eliteCreator || eventAddon ?  */}
                                        <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer' onClick={addEvent}>
                                            <img src={Ticket} className='w-[40px] mr-4 ' />
                                            <div className='flex flex-col '>
                                                <h2 className='font-bold text-sm'>Manage Event</h2>
                                                <p className='font-normal text-xs'>Handle your events like a pro!</p>
                                            </div>
                                        </div>
                                        
                                        {/* :
                                        <div className='flex items-center mt-5 p-4 border-[1px] rounded-3xl border-[#D2D8C7] cursor-pointer opacity-50' onClick={() => navigate('/pricing')}>
                                            <img src={Ticket} className='w-[40px] mr-4 ' />
                                            <div className='flex flex-col '>
                                                <h2 className='font-bold text-sm'>Manage Event</h2>
                                                <p className='font-normal text-xs'>Handle your events like a pro!</p>
                                            </div>
                                        </div>
                                    } */}
                                </>
                            }

                            

                        </div>  
                    </>

                </TEModalBody>
                <TEModalFooter>
                    <TERipple rippleColor="light">
                    {/* <button
                        type="button"
                        className="inline-block rounded bg-primaryy-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primaryy-700 transition duration-150 ease-in-out hover:bg-primaryy-accent-100 focus:bg-primaryy-accent-100 focus:outline-none focus:ring-0 active:bg-primaryy-accent-200"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </button> */}
                    </TERipple>
                    
                </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
            </TEModal>

            {/* MODAL FOR PAYALL */}
            <PaywallModal showPaywallModal={showPaywallModal} setShowPaywallModal={setShowPaywallModal} setShowModal={setShowModal} showModal={showModal} />
            <TicketsModal showEvent={showEvent} setShowEvent={setShowEvent} setShowModal={setShowModal} showModal={showModal} />
            <NormalModal showNormalModal={showNormalModal} setShowNormalModal={setShowNormalModal} setShowModal={setShowModal} showModal={showModal} />
            <GiftModal showGift={showGift} setShowGift={setShowGift} setShowModal={setShowModal} showModal={showModal}  />
        </div>
    );
  }
  
  export default AddBlockBtn;