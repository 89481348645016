import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './home.css';



import SignArrow from '../../images/circular-arrow.png';
import featuresbg from '../../images/featuresbg.png';
import manageevents from '../../images/manageevents.png';
import trackanalytics from '../../images/trackanalytics.png';
import embedvid from '../../images/embedvid.png';
import embedaud from '../../images/embedaud.png';
import curatelinkpng from "../../images/curatelinkpng.png"
import sellproducts from "../../images/sellproducts.png"
import gettipped from "../../images/gettipped.png"
import ime from "../../images/ime.png"
import sellcontent from "../../images/sellcontent.png"
import mediafile from "../../images/mediafile.png"
import cleanlayout from "../../images/cleanlayout.png"
import livevent from "../../images/livevent.png"
import coffee from "../../images/coffee.png"
import pricecof from "../../images/pricecof.png"
import signupicon from "../../images/signupicon.png"
import cookie from "../../images/cookie.png"
import foward from "../../images/foward.png"

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import ReactGA from "react-ga4";
import Faqs from './faqs.tsx';
import Footer from './footerlp.tsx';


function Features() {
  const [username, setUsername] = useState('')


  const handleClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked My Button',
      label: 'Button Click',
    });
    navigate('/register');
  }
 
    return (
  <div>
    <div className="bg-[#121B00] pt-32 lg:pt-36 h-full relative pb-10">
    <div  className='p-3 mb-4 lg:mb-7'>
          <h1 className='text-neutral-100 text-5xl lg:text-7xl font-black text-center'>ONE GATEWAY TO ALL  <br className='hidden lg:block' /> YOUR<br className='lg:hidden' /> CONTENTS</h1>
          
          <div className='p-3 pb-12'>
          <p className='text-lg text-center text-neutral-400 font-semibold'>Showcase and share all your content across all your platforms with one single <br/>personalised page. Stay ahead of your content game.</p>
        </div>
        </div>
        <div className='flex flex-col p-3 max-w-lg justify-center m-auto lg:justify-between lg:max-w-xl lg:flex-row lg:items-center lg:mt-4 lg:pt-3 box-border'>
          <div className="flex px-4 py-2 justify-between items-center font-bold lg:mr-3 rounded-full  bg-white text-base lg:text-xl lg:px-4 lg:py-2 lg:w-full lg:mt-0">
              <div className='flex items-center'>
                <p className="text-neutral-400">
                  prymshare.co/
                </p>
                <p><input className="m-0 p-0 w-full border-0 font-bold placeholder:text-neutral-400 outline-0 outline-none"
                  type='text' name='username' value={username} placeholder="username" onChange={(e) => setUsername(e.target.value)}
                /></p>
              </div>
              <div onClick={handleClick} className='p-2 rounded-full lg:p-2 bg-primaryy-500 cursor-pointer'>
                <img src={SignArrow} className='block w-5 lg:w-7' alt='Sign up button' />
              </div>
          </div>
          
    </div>
    <div className='text-white m-auto flex justify-center items-center gap-[200px] mt-10 p-5' >
           <img
           className='w-[250px] hidden lg:block'
           src={manageevents}
           />
              <img
           className='w-[250px] hidden lg:block'
           src={trackanalytics}
           />
          </div>

          <div className='text-white m-auto flex justify-center items-center gap-[200px] mt-5 p-5' >
           <img
           className='w-[250px] hidden lg:block'
           src={embedaud}
           />
              <img
           className='w-[250px] hidden lg:block'
           src={curatelinkpng}
           />
                 <img
           className='w-[250px] hidden lg:block'
           src={embedvid}
           />
          </div>

          <div className='text-white m-auto flex justify-center items-center gap-[200px] mt-10 p-5' >
           <img
           className='w-[250px] hidden lg:block'
           src={sellproducts}
           />
              <img
           className='w-[250px] hidden lg:block'
           src={gettipped}
           />
          </div>

  </div>
  <div>
     <div className='text-center pt-20 pb-15'>  
       <h1 className=' text-5xl md:text-7xl font-black pb-5 text-[#121B00] '>FIND A TEMPLATE AND <br/>MAKE IT YOUR OWN</h1>
       <p className='text-[#797E6F]'>Explore our templates to find one that fits your brand as a creator. We made it easy  for <br/> you to build your dream page</p>
       <div className='pt-10  md:max-w-[1240px] w-[80%] mx-auto pb-[150px]'>
          <img src={featuresbg} alt=''/>
       </div>
     </div>
  </div>
  


  <div className='bg-[#F7F9F4] py-[100px] grid  grid-cols-1 md:grid-cols-3 gap-[70px] px-20  place-items-center'>

  <div className='max-w-[392px] md:w-[100%] text-center md:text-start'>
     <img src={ime} alt="" className='py-2'/>
      <h1 className='text-2xl font-bold py-2 '>Easy Build</h1>
      <p className='font-thin py-2 '>Build your page the way you want with no code. <br/> Open your account and start adding blocks.</p>
     </div>

 
     <div className='max-w-[392px] md:w-[100%] text-center md:text-start'>
     <img src={sellcontent} alt="" className='py-2'/>
      <h1 className='text-2xl font-bold py-2 '>Clean Layout</h1>
      <p className='font-thin py-2 '>From colour schemes to texts to layout, we’ve designed <br/> everything to keep your page neat and organized.</p>

     </div>

 
     <div className='max-w-[392px] md:w-[100%] text-center md:text-start'>
     <img src={mediafile} alt="" className='py-2'/>
      <h1 className='text-2xl font-bold py-2 '>Media files</h1>
      <p className='font-thin py-2 '>Embed your videos, music, shorts, podcasts, and so much <br/> more. Prymshare is optimized for your content .</p>
     </div>

     <div className='max-w-[392px] md:w-[100%] text-center md:text-start'>
     <img src={cleanlayout} alt="" className='py-2'/>
      <h1 className='text-2xl font-bold py-2 '>Sell your Content</h1>
      <p className='font-thin py-2 '>Set up a store to sell your courses with a smooth checkout <br/> process to ensure payments from anywhere, anytime.</p>
     </div> 

     
     <div className='max-w-[392px] md:w-[100%] text-center md:text-start'>
     <img src={livevent} alt="" className='py-2'/>
      <h1 className='text-2xl font-bold py-2 '>Host Events</h1>
      <p className='font-thin py-2 '>Manage all your event needs, from registration to ticket <br/> sales in one place.</p>
     </div> 

     <div className='max-w-[392px] md:w-[100%] text-center md:text-start'>
     <img src={pricecof} alt="" className='py-2'/>
      <h1 className='text-2xl font-bold py-2 '>Get Supported</h1>
      <p className='font-thin py-2 '>Accept support from your followers for your works.<br/> Monetize by accepting tips.</p>
     </div>  
  </div>
    <div className='pt-[200px] text-center pb-[200px] '>
      <h1 className='text-5xl md:text-7xl font-black pb-5 px-5'>
       WE'VE MADE IT SUPER <br/> EASY TO GET STARTED
      </h1>
      <p className='font-thin pb-[100px] px-5'>
      Get stared with Prymshare and set up your profile in no time.
      </p>

      <div className='flex justify-evenly items-center flex-nowrap  xs:flex-wrap gap-10'>
        <div className='max-w-[392px] md:w-[100%] w-[70%] h-[390px] bg-[#F7F9F4] text-start flex flex-col justify-between'>
          <div>
             <img src={signupicon} className='w-[80px] pl-5 pt-10 ' alt=""/>
          </div>
           <div className='py-5 px-5'>
              <h1 className='py-3 text-2xl md:text-3xl font-bold'>Sign up and create your prymshare account</h1>
             <p className='py-3 font-thin'>Sign up and get your Prymshare account set up in just a few minutes. Get started today.</p>
           </div>
        </div>

        <div className='max-w-[392px] md:w-[100%] w-[70%] h-[390px] bg-[#F7F9F4] text-start flex flex-col justify-between'>
          <div>
             <img src={cookie} className='w-[80px] pl-5 pt-10 ' alt=""/>
          </div>
           <div className='py-5 px-5'>
              <h1 className='py-3 text-2xl md:text-3xl font-bold'>Customize your page and make it your own</h1>
             <p className='py-3 font-thin'>Own your page and customize it to fit your brand. Create a page that sets you apart.</p>
           </div>
        </div>

        <div className='max-w-[392px] md:w-[100%] w-[70%] h-[390px] bg-[#F7F9F4] text-start flex flex-col justify-between'>
          <div>
             <img src={foward} className='w-[80px] pl-5 pt-10 ' alt=""/>
          </div>
           <div className='py-5 px-5'>
              <h1 className='py-3 text-2xl md:text-3xl font-bold'>Update your bio link and you are all set</h1>
             <p className='py-3 font-thin'>Add your personalized Prymshare URL to your bio across all your platform. Unify your content.</p>
           </div>
        </div>
      </div>
      
    </div>
 
    <div className="p-4 py-16 lg:py-24 mt-12 lg:mt-16 bg-[#F7F9F4]">
        <div className='flex flex-col justify-between items-center max-w-6xl m-auto'>
            <div className='flex flex-col items-start'>
                <div className='flex flex-col mb-9'>
                    <AnimationOnScroll animateIn="slideInRight" duration="0.5"><h1 className='text-[#121B00] pb-0 text-4xl lg:text-5xl ml-0 font-black text-center lg:text-start mb-4'>STILL <br className='lg:hidden' />WONDERING?</h1></AnimationOnScroll>
                </div>
            </div>

            <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='flex w-full flex-col max-w-2xl'>
                <Faqs />
            </AnimationOnScroll>
        </div>

        </div>
        <Footer/>
  </div>
    );
  }
  
  export default Features;
  