import React,{useState, useEffect} from 'react'
import calendar from "../../../images/calendar.png"
import clock from "../../../images/clock.png"
import gps from "../../../images/gps.png"
import plus from "../../../images/plus.png"
import minus from "../../../images/minus.png"
import check from "../../../images/check.png"

const EventmodalSteps = ({humanReadableDate, event_detailsArray, creatorId, setOpen}) => {


  const [currentStep, setCurrentStep] = useState(0);
  const [ticketNo, setTicketNo] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0)
  const [formData, setFormData] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formError, setFormError] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
    
  const [ticketQuantities, setTicketQuantities] = useState(
    event_detailsArray.tickets.map(() => 0)
  );

  const [categoryTotals, setCategoryTotals] = useState(
    event_detailsArray.tickets.map(() => 0)
  );

  const [categoryNames, setCategoryNames] = useState(
    event_detailsArray.tickets.map((ticket) => ticket.name)
  );

  const [ticketId, setTicketID] = useState(
    event_detailsArray.tickets.map((ticket) => ticket.id)
  );


  // const nextStep = () => {
  //   if (currentStep >=1 && ticketQuantities.some((quantity) => quantity > 0)) {
  //     setCurrentStep((prev) => prev + 1);
  //     setShowErrorMessage(false);
  //   } else if (currentStep === 0) {
  //     setCurrentStep((prev) => prev + 1);
  //     setShowErrorMessage(false);
  //   } else {
  //     setShowErrorMessage(true);
  //   }
  // };
    const handleClick = (e) => {
    
    if (Object.keys(formData).length < 0) {
            setFormError(true);
            return
    } else {
       handleSubmit(e);
    }
  };
const nextStep = (e) => {

  if (currentStep === 2) {
    const hasEmptyFields = categoryNames.some((categoryName, index) => {
      const quantity = ticketQuantities[index]; 
      for (let i = 0; i < quantity; i++) {
     
        if (
          !formData[`${categoryName}-${i}-name`] ||
          !formData[`${categoryName}-${i}-email`]
          // !formData[`${categoryName}-${i}-phone`]
        ) {
          return true; // Some form fields are empty
        }
      }
      return false; // All fields are filled
    });

    if (hasEmptyFields) {
      setFormError(true); // Display error message
      return;
    } else {
      setFormError(false); // Hide error message
       handleSubmit(e)
      // setCurrentStep((prev) => prev + 1); // Move to the next step if all fields are filled
    }
  } else if (currentStep >= 1 && ticketQuantities.some((quantity) => quantity > 0)) {
    // If it's not the form step, check if any ticket is selected
    setCurrentStep((prev) => prev + 1);
    setShowErrorMessage(false);
  } else if (currentStep === 0) {
    // First step, no validation needed, just move to the next step
    setCurrentStep((prev) => prev + 1);
    setShowErrorMessage(false);
  } else {
    setShowErrorMessage(true); // No tickets selected, show error message
  }
};


  

  const prevStep = () => setCurrentStep((prev) => prev - 1);

  // const nextTicketStep = () => setTicketNo((prev) => prev + 1);
  // const prevTicketStep = () => setTicketNo((prev) => prev - 1);



  const handleTicketChange = (index, change) => {
  
    setTicketQuantities((prevQuantities) => {
      const updatedQuantities = [...prevQuantities];
      updatedQuantities[index] = Math.max(0, updatedQuantities[index] + change);
      const newTotalPrice = updatedQuantities.reduce((total, quantity, ticketIndex) => {
        return total + quantity * event_detailsArray.tickets[ticketIndex].price;
      }, 0);
      setTotalPrice(newTotalPrice);
      const hasSelectedTickets = updatedQuantities.some((quantity) => quantity > 0);
      console.log(hasSelectedTickets)
      setShowErrorMessage(!hasSelectedTickets);
      return updatedQuantities;
    });

    setCategoryTotals((prevTotals) => {
      const updatedTotals = [...prevTotals];
      updatedTotals[index] = updatedTotals[index] + change;
      console.log(updatedTotals)
      return updatedTotals;
    });

    setCategoryNames((prevNames) => {
      const updatedNames = [...prevNames];
      return updatedNames;
    });

 
    
  };



const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData((preData) => ({
    ...preData,
    [name]: value,
  }));

  // Recheck form for empty fields and reset formError if everything is filled
  const hasEmptyFields = categoryNames.some((categoryName, index) => {
    const quantity = ticketQuantities[index];
    for (let i = 0; i < quantity; i++) {
      if (
        !formData[`${categoryName}-${i}-name`] ||
        !formData[`${categoryName}-${i}-email`]
        // !formData[`${categoryName}-${i}-phone`]
      ) {
        return true;
      }
    }
    return false;
  });

  if (!hasEmptyFields) {
    setFormError(false); // Reset formError when all fields are filled
  }
  console.log(formData)
};

const initializePayment = async () => {
  setLoadingSubmit(true);
  // Preparing ticket data to be sent to the backend
  const ticketData = categoryNames.map((categoryName, index) => {
      const quantity = ticketQuantities[index];
      const details = [];
      
      const ticketIdForCategory = ticketId[index];

      for (let i = 0; i < quantity; i++) {
          details.push({
              name: formData[`${categoryName}-${i}-name`],
              email: formData[`${categoryName}-${i}-email`],
              // phone: formData[`${categoryName}-${i}-phone`],
              ticketCategory: categoryName,  // Add ticket category name
              ticketId: ticketIdForCategory,
          });
      }
      
      return details;
  }).flat();

  // Creating the payload
  const payload = {
      creator_id: creatorId,
      type: 'event',
      eventId: event_detailsArray.id,  // Assuming event ID is part of event details
      tickets: ticketData,  // Array of ticket data
      amount: totalPrice,
  };

  try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscriptions/initialize_payment/`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
      });

      const data = await response.json();
      // if (data.status === 'success') {
      //     // Redirect to payment gateway or handle free ticket case
      //     window.location.href = data.authorization_url || 'check';  // Redirect to payment or thank you page
      //     setLoadingSubmit(false);
      // } else {
      //     alert(data.message);
      //     setLoadingSubmit(false);
      // }

      if (data.status === 'success') {
        // If it's a free ticket, display an alert
        if (!data.authorization_url) {
            alert('Thank you! Your free ticket has been processed. Check your email for details.');
            setLoadingSubmit(false);
            setOpen(false);
        } else {
            // Redirect to payment gateway for paid tickets
            window.location.href = data.authorization_url;
        }
      } else {
          alert(data.message);
          setLoadingSubmit(false);
      }
  } catch (error) {
      console.error('Error during payment initialization:', error);
      setLoadingSubmit(false);
  }
};

  
  const handleSubmit = (e) => {
    e.preventDefault();   

    // console.log(formData);   
    initializePayment();

  };

    
    // const hasEmptyFields = Object.values(formData).some((value) => !value);
    //   console.log(hasEmptyFields);
      
    //   if (hasEmptyFields) {
    //   setFormError(true);
    //     console.log(formError);
    //   return;
    // }
    

  // const handleClick = (e) => {
    
  //   if (Object.keys(formData).length < 0) {
  //           setFormError(true);
  //           return
  //   } else if(Object.keys(formData).length > 0){
 
  //        handleSubmit(e);
  //   } else {
  //      nextStep();
  //   }
  // };

  



    const renderForms = () => {
    return categoryNames.map((categoryName, index) => {
      const quantity = ticketQuantities[index];
      return (
        <div key={index}>
          <h2 className="font-bold text-base">{categoryName}</h2>
          <p className="my-1 font-light mb-10">
            Total Tickets Selected: {categoryTotals[index]}
          </p>
          {quantity > 0 && (
            <form onSubmit={handleSubmit}>
              {[...Array(quantity)].map((_, i) => (
                <div key={i} className='flex flex-col gap-3 my-4'>
                  <input 
                    type="text"
                    name={`${categoryName}-${i}-name`}
                    placeholder="Name"
                    onChange={handleInputChange}
                    className={`bg-[#EEF1E8] p-3 rounded-2xl ${formData[`${categoryName}-${i}-name`] ? '' : 'border-red-500'}`}
                  />
                 <input className='bg-[#EEF1E8] p-3 rounded-2xl'
                    type="email"
                    name={`${categoryName}-${i}-email`}
                    placeholder="Email"
                    onChange={handleInputChange}
                  />
                  {/* <input className='bg-[#EEF1E8] p-3 rounded-2xl mb-4'
                    type="tel"
                    name={`${categoryName}-${i}-phone`}
                    placeholder="Phone Number"
                    onChange={handleInputChange}
                  /> */}
                </div>
              ))}
            </form>
          )}
        </div>
      );
    });
  };

  

  return (
    <div>


{
  currentStep === 0 && 
  <>
       <div>
   <img src={event_detailsArray.image} alt='' className='w-full h-[350px] object-cover object-top'/>
   </div>

   <div className='mt-5 '>
              <h1 className='text-base font-bold'>{event_detailsArray.title}</h1>

              <div className='py-3  mt-3 flex gap-5'>
                <img src={calendar} alt=''/>
                <h1>{humanReadableDate}</h1>
              </div>

              <div className='py-3 flex gap-5'>
                <img src={clock} alt=''/>
                <h1>{event_detailsArray.time}</h1>
              </div>

              <div className='py-3 flex gap-5'>
                <img src={gps} alt=''/>
                <h1>{event_detailsArray.location}</h1>
              </div>
            </div>
    
            <hr
      className="my-6 h-0.5 border-t-0 bg-neutral-100 opacity-100 dark:opacity-50"
    />


<div>
  <h1 className='text-1xl font-bold'>About the event</h1>
  <p className='py-2'>{event_detailsArray.description}</p>
</div>
  </>
}

{
  currentStep === 1 &&
  <>
    <div>
      
      <h1 className='font-bold text-base my-4 pb-5'>Select Tickets</h1>
     {event_detailsArray.tickets.map((array, index) => (
      <div key={index} className='pb-5'>
        {/* <p className="my-1 font-light mb-10">
      Total Tickets Sold: {categoryTotals[index]}
    </p> */}
       <h1 className='font-bold text-base mt-2'> 
       {array.name}
       </h1>
        <p className='my-1 font-light mb-10'>
        {array.description}
        </p>
        <div className='flex justify-between'>
        <h1 className='font-bold text-base mt-2'> 
        NGN{ticketQuantities[index] == 0 ? array.price : array.price * ticketQuantities[index]}
       </h1>
           <div className='flex gap-2 bg-[#EEF1E8] items-center px-4 rounded-full'>
           <img
                      src={minus}
                      className="h-[20px]"
                      onClick={() => handleTicketChange(index, -1)}
                    />
                    <h1>{ticketQuantities[index]}</h1>
                    <img
                      src={plus}
                      className="h-[20px]"
                      onClick={() => handleTicketChange(index, 1)}
                    />
            </div>
        </div>
       <hr
      className="my-6 h-0.5 border-t-0 bg-neutral-200 opacity-100 dark:opacity-50"
    />
      </div>
     ))}
     {/* {categoryNames.map((categoryName, index) => (
  <div key={index}>
    <h2 className="font-bold text-base">{categoryName}</h2>
    <p className="my-1 font-light mb-10">
      Total Tickets Sold: {categoryTotals[index]}
    </p>
  </div>
))} */}
    </div>
  </>
}

{currentStep === 2 && (
        <>
          <div>
            <h1 className="font-bold text-lg my-4 pb-5">Enter Details</h1>
            {renderForms()}
          </div>
        </>
      )}

<div className="bg-[#121B00]  py-3 px-10 rounded-3xl mt-3 text-[#D2D8C7] flex flex-col items-center justify-center cursor-pointer" onClick={nextStep}>
        {loadingSubmit ? 
            <div
                className="inline-block h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
                <span
                  className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                >Loading...</span>
              </div> :
              <button
                className='outline-none'
                disabled={showErrorMessage || formError}
                >
                  Get Tickets
                  {totalPrice > 0 && (
                    <span className="ml-2">NGN{totalPrice}</span>
                  )}
              </button>
            }
        
        {showErrorMessage && (
          <p className="text-blue-500 ml-4">Please select at least one ticket.</p>
        )}
          {formError && (
          <p className="text-red-500 mt-2">Please fill in all required fields.</p>
        )}
      </div>
</div>
  )
}

export default EventmodalSteps