import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Link
} from "react-router-dom";

import Menu from '../../images/menu-page.png';
import Share from '../../images/page-share.png';
import Bell from '../../images/Bell.png';

import { creatorpagedata } from "../../state/atoms/Creator";

import { RWebShare } from "react-web-share";


function PageNav() {
    const [creatorData, setCreatorData] = useRecoilState(creatorpagedata)
    let username = creatorData.creator.creator_username
    let creator_link = `${process.env.REACT_APP_FRONTEND_URL}/${username}`;

    const [showModal, setShowModal] = useState(false);

    useEffect (() => {
        username = creatorData.creator.creator_username
        creator_link = `${process.env.REACT_APP_FRONTEND_URL}/${username}`;
    }, [creatorData])

    return (
        <div className='s-page-nav'>
            <main className="page-nav">
                <div className='flex invisible p-[10px] px-[20px] justify-between items-center bg-white rounded-full cursor-pointer'>
                    <img src={Bell} className='w-[17px] mr-2' />
                    <h3 className='text-[#121B00] text-[85%] font-bold'>Subscribe</h3>
                </div>
                
                <RWebShare
                    data={{
                        url: creator_link,
                        title: username,
                    }}
                    onClick={() => console.log("shared successfully!")}
                    
                >
                    <div className='share-nav'>
                        <img className='w-[16px]' src={Share} />
                    </div>
                </RWebShare>
            
            </main>
            {showModal ?
                <div className='nav-modal'>
                    <p>Become a Prime Creator!</p>
                    <Link to='/register'>Register with Prymshare</Link>
                    <Link to='/feedback'>Submit Feedback</Link>
                </div> 
                :
                <></>
            }
            
        </div>
    );
  }
  
  export default PageNav;
  