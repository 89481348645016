import { useEffect, useState } from 'react';
import {Link, useParams, useLocation} from "react-router-dom";
import axios from 'axios';
import Axios from 'axios';

import './biopage.css';
import Intro from './intro.tsx';
import Frame from './pictureframes.tsx';
import Video from './videos/video.tsx';
import Gallery from './gallery/gallery.tsx';
import Audio from './audios/audio.tsx';
import TextBlock from './textblock/textblock.tsx';
import Links from './links/links.tsx';
import Divider from './divider.tsx';
import GiftBlock from './giftblock/giftblock.tsx';
import PayWallBlock from './paywall/paywall.tsx';
import { useRecoilState, useRecoilValue } from 'recoil';

import { iscreator, creatorpagedata, creatorps, loggedcreatordata } from "../../state/atoms/Creator";

import { Helmet } from 'react-helmet';

import Watermark from '../../images/watermark.png';
import Logo from '../../images/logo-t.png';
import Curate from '../../images/curate-this.png';

import usePageViewTracker from '../Analytics/usePageViewTracker.tsx';
import useLinkClickTracker from '../Analytics/useLinkClickTracker.tsx';


function MainPage() {
  const location = useLocation();
  
  const { id } = useParams();

  usePageViewTracker(id);

  const [creatorData, setCreatorData] = useRecoilState(creatorpagedata);
  const [sectionOrder, setSectionOrder] = useRecoilState(creatorps);
  const isCreator = useRecoilValue(iscreator);

  // const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);


  const [linkButtons, setLinkButtons] = useState(creatorData.links);
  const [loadingLinks, setLoadingLinks] = useState(true);
  // const [videoSection, setVideoSection] = useState(creatorData.vsection);
  // const [videoPost, setVideoPost] = useState(creatorData.vposts);
  // const [gallerySection, setGallerySection] = useState(creatorData.gsection);
  // const [galleryPost, setGalleryPost] = useState(creatorData.gposts);
  // const [vidHighlightsCount, setVidHighlightsCount] = useState(0);
  // const [loadingVideo, setLoadingVideo] = useState(true);
  const [giftBlock, setGiftBlock] = useState(creatorData.giftblock);
  // const [audHighlightsCount, setAudHighlightsCount] = useState(0);
  // const [loadingAudio, setLoadingAudio] = useState(true);
  // const [textBlock, setTextBlock] = useState(creatorData.textblock);
  // const [loadingText, setLoadingText] = useState(true);
  // const [PLsection, setPLSection] = useState(creatorData.paywall);
  // const [loadingPL, setLoadingPL] = useState(true);
  // const [structure, setStructure] = useState();
  // const [loadingStructure, setLoadingStructure] = useState(true);

  const [creatorNameLoaded, setCreatorNameLoaded] = useState('');

  const [theme, setTheme] = useState(creatorData.creator.theme);


  useEffect(() => {
    setLinkButtons(creatorData.links);
    setGiftBlock(creatorData.giftblock);
    // setPLSection(creatorData.paywall);
    // setVideoSection(creatorData.vsection);
    // setVideoPost(creatorData.vposts);
    // setGallerySection(creatorData.gsection);
    // setGalleryPost(creatorData.gposts);
    setTheme(creatorData.creator.theme);

    setCreatorNameLoaded(creatorData.creator.display_name);
}, [creatorData]);
  
  // useEffect(() => {
  //   setLinks(creatorLinks)
  // }, [creatorLinks]);

  // let theCreatorNameLoaded = creatorData.creator.display_name;
  // if (!sectionOrder || Object.keys(sectionOrder).length === 0 ) {
  //   return <p>Loading...</p>
  // }

  if (!creatorData.creator || Object.keys(creatorData.creator).length === 0) {
    // If pageStructure is undefined or empty, return null or a loading indicator
    // console.log(creatorData.creator)
    // console.log(Object.keys(creatorData.creator).length)
    return <div className='p-12 h-screen bg-[#121B00] '><div
            className="flex  m-auto mt-48 h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-white border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
          </div></div>; // or return a loading indicator component
  }
  

  
  return (
    <main className={"dark mainpage"}>
      <Intro username={id} />

      {linkButtons.length != 0 ? <Links links={linkButtons} creatorId={creatorData.creator.id} /> : <></>}

      {giftBlock == null ? <></> : 
        <>
          {Object.keys(giftBlock).length === 0 ? <></> : 
            <GiftBlock creatorId={creatorData.creator.id} creatorName={creatorNameLoaded} giftblock={giftBlock}  />
          }
        </>
      }
      
      {/* {sectionOrder.map((section, index) => {
        // console.log(section)
        // console.log(sectionOrder)
          switch (section) {
              case 'VideoBlock':
                  return <Video videoSection={videoSection} videoPost={videoPost} />;
              case 'GalleryBlock':
                  return <Gallery gallerySection={gallerySection} galleryPost={galleryPost} />;
              case 'PaywallBlock':
                  return <PayWallBlock plsection={PLsection} />;
              case 'GiftBlock':
                  return <GiftBlock creatorId={creatorData.creator.id} giftblock={giftBlock} />;
              case 'TextBlock':
                  return <TextBlock textBlock={textBlock} />;
              // Add cases for other section types
              default:
                  return null;
          }
      })} */}

      <Link to='/register' className='flex justify-center'>
        <div className='watermark py-3 px-5 bg-black items-center space-between rounded-full'>
          <p className='font-bold !text-white text-base mr-2'>Join {creatorNameLoaded} on Prymshare</p><img src={Logo} className='w-[21px]' />
        </div>
        
      </Link>
      
      
    </main>
  );
}



export default MainPage;
