import React from 'react'
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

import ReactGA from "react-ga4";
import Faqs from './faqs.tsx';
import Footer from './footerlp.tsx';

const Pricing = () => {
  return (
 <div>
        <div className='pt-20 bg-[#121B00]  pb-20 mx-auto text-center flex flex-col gap-5 justify-center items-center'>
        <div className='pt-10 mt-20 px-3 text-[#F9FAF4]'>
            <h1 className='text-4xl md:text-6xl font-bold pb-5 '>Simple Pricing. Sign Up for free.</h1>
            <p>Every feature on Prymshare is free. We only get paid when you get paid! For every transaction, a fee applies:</p>
       </div>
       <div className='py-[60px] text-center mt-20 px-[100px] md:px-[300px] border-[#F9FAF4] text-[#F9FAF4] border-2 rounded-[25px] p-10'>
            <h1 className='text-4xl md:text-6xl font-semibold'> 6% + ₦100</h1>
            <p className='text-base md:text-lg font-semibold py-2'>per transaction</p>
       </div>

    </div>

    <div className="p-4 py-16 lg:py-24  bg-[#F7F9F4]">
        <div className='flex flex-col justify-between items-center max-w-6xl m-auto'>
            <div className='flex flex-col items-start'>
                <div className='flex flex-col mb-9'>
                    <AnimationOnScroll animateIn="slideInRight" duration="0.5"><h1 className='text-[#121B00] pb-0 text-4xl lg:text-5xl ml-0 font-black text-center lg:text-start mb-4'>STILL <br className='lg:hidden' />WONDERING?</h1></AnimationOnScroll>
                </div>
            </div>

            <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='flex w-full flex-col max-w-2xl'>
                <Faqs />
            </AnimationOnScroll>
        </div>

        </div>
        <Footer/>
 </div>
  )
}

export default Pricing