// CreatorDashboard.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';


const  Account = () => {
  const [dashboardData, setDashboardData] = useState({
    total_paywall_amount: 0,
    total_gift_amount: 0,
    paywall_amounts: [{}, {}, {}],
    event_amounts: [{}, {}, {}],
  });

  useEffect(() => {
    const config = {
      headers: {
          'Content-Type': 'multipart/formdata',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
          // 'Accept': 'multipart/formdata'
      } 
    }; 
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/profile/creator_dashboard/`, config);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="container mx-auto p-1 mb-24" style={{fontSize: '70%'}}>
      <div style={{marginTop: '80px'}} className="bg-[#121B00] text-white shadow-md rounded-[23px] p-[7.5%] mb-4">
        <h2 className="text-sm font-semibold mb-2">Total Earnings</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 pl-0 bg-green-100 rounded">
            <h3 className="text-xs font-medium">Paywall Links</h3>
            <p className="text-sm font-bold">₦{dashboardData.total_paywall_amount }</p>
          </div>
          <div className="p-4 pl-0 bg-green-100 rounded">
            <h3 className="text-xs font-medium">Event Tickets</h3>
            <p className="text-sm font-bold">₦{dashboardData.total_event_amount }</p>
          </div>
          <div className="pb-4 bg-blue-100 rounded">
            <h3 className="text-xs font-medium">Gifts</h3>
            <p className="text-sm font-bold">₦{dashboardData.total_gift_amount }</p>
          </div>
        </div>
      </div>
      {dashboardData.total_paywall_amount == 0 ? <></> :
        <div className="bg-white shadow-md rounded p-4">
          <h2 className="text-sm font-semibold mb-2">Paywall Link Earnings</h2>
          <ul>
            {dashboardData.paywall_amounts.map((paywall) => (
              <li key={paywall.title} className="mb-2">
                <div className="p-4 pl-0 bg-gray-100 rounded">
                  <h3 className="text-xs font-medium">{paywall.title}</h3>
                  <p className="text-sm font-bold">₦{paywall.total_amount }</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      }

      {dashboardData.total_event_amount == 0 ? <></> :
        <div className="bg-white shadow-md rounded p-4 mt-7">
          <h2 className="text-sm font-semibold mb-2">Event Tickets Earnings</h2>
          <ul>
            {dashboardData.event_amounts.map((event) => (
              <li key={event.name} className="mb-2">
                <div className="p-4 pl-0 bg-gray-100 rounded">
                  <h3 className="text-xs font-medium">{event.name}</h3>
                  <p className="text-sm font-bold">₦{event.total_amount }</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      }
    </div>
  );
};

export default Account;
