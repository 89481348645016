import React, {useState} from "react"
import { useNavigate } from 'react-router-dom';

import Logo from '../../images/new_logo.png';
import check from '../../images/check.png'
import billing from '../../images/billing.png'
import Icon from '../../images/Icon.png'
import left from '../../images/left.png'
import  premium from "../../images/premium.png"
import small from "../../images/small.png"


const Billing = () => {
  const navigate = useNavigate();

    return(
        <div className="bg-[#F0F0EF] h-full px-4 py-20 w-[100%]">
          <div className=" py-2">
          <div className="mb-8">
            <img src={Logo} alt="" className="w-[150px] hidden md:block"/>
            <img src={small} alt="" className="h-[40px] block md:hidden"/>
          </div>
          <div className="flex gap-3 items-center cursor-pointer" onClick={() => navigate(-1)}>
            <img src={left} alt="" className=""/>
            <h3 className="text-[#FF1493]">Go back</h3>
          </div>
          </div>
          <div className="flex flex-col justify-center w-[100%] items-center ">
          <div>
             <h1 className=" text-2xl sm:text-2xl font-extrabold text-center ">PRYMSHARE PRO FEATURES</h1>
            </div>
            <div>
            <h1 className="p-2 text-sm text-center">Explore more options with Prymshare!</h1>
            </div>
            <div className="flex flex-col md:flex-row bg-white p-3 mt-6 gap-5 mb-10 pb-5 mt-10 rounded-[24px] ">
              <div className="bg-[#121B00] w-[100%] md:w-[50%] h-[100%] rounded-[20px]">
               <img src={premium} alt=""/>
              </div>
              <div className="flex flex-col">
                 <h3 className="text-lg font-bold py-4">Pro</h3>
                 <p className='text-sm'>Explore more options with Prymshare</p>
                 <div className="flex gap-3 items-center mt-[20px] py-[8px]">
                  <img src={check} className="w-[16px] h-[16px]" alt=""/>
                  <h3 className="font-semibold text-sm">Sell your online courses from one page</h3>
                 </div>

                 <div className="flex items-center gap-3 mt-[8px]">
                  <img src={check} alt="" className="w-[16px] h-[16px]" />
                  <h3 className="font-semibold text-sm">Manage events ticketing</h3>
                 </div>

                 <div className="flex items-center gap-3 mt-[8px]">
                  <img src={check} alt="" className="w-[16px] h-[16px]" />
                  <h3 className="font-semibold text-sm">Manage and monetize one-on-one appointments</h3>
                 </div>

                 <div className="flex items-center gap-3 mt-[8px]">
                  <img src={check} alt="" className="w-[16px] h-[16px]" />
                  <h3 className="font-semibold text-sm">Make use of creative customizations for your page</h3>
                 </div>

                 <div className="flex items-center gap-3 mt-[8px]">
                  <img src={check} alt="" className="w-[16px] h-[16px]" />
                  <h3 className="font-semibold text-sm">Embed your video and audio highlights on your Prymshare page</h3>
                 </div>
                 {/* <h3 className="text-1xl font-bold mt-[40px]">Free for 30 days</h3> */}
                 {/* <h3 className='mt-[40px]'>NGN 5000 per month</h3> */}
                 <div className="flex gap-1 py-3 w-[100%] bg-[#121B00] mt-[20px] justify-center rounded-full items-center text-white cursor-pointer">
                  <img src={Icon} alt=""/>
                  <h3 className='text-sm'>Pro features coming soon</h3>
                 </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Billing