import React, {useState, useRef} from "react"
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TECollapse,
} from "tw-elements-react";

import { useRecoilState, useRecoilValue } from 'recoil';
import { creatorlinks } from "../../../state/atoms/Creator";

import { addCreatorLinks, addCreatorEvent } from '../../../state/actions/creator';

import imageCompression from 'browser-image-compression';

import Delete from '../../../images/newtrash.png';



const TicketsModal = ({ showEvent, setShowEvent, setShowModal, showModal }) => {
    const [step, setStep] = useState(1);
    const [linktitle, setLinktitle] = useState('')
    const [description, setDescription] = useState('')
    const [url, setUrl] = useState('')
    const [linkType, setLinkType] = useState('paywalled')
    const [price, setPrice] = useState(0)
    const [date, setDate] = useState('')
    const [time, setTime] = useState()
    const [eventLocation, setEventLocation] = useState('')
    const [organizer, setOrganizer] = useState('')
    const [joinLink, setJoinLink] = useState('')

      // State for ticket details
    const [ticket, setTicket] = useState({ name: '', description: '', price: 0, currency: 'NGN' });
    
    // State to hold the added tickets
    const [tickets, setTickets] = useState([]);

    const [loading, setLoading] = useState(false)
    const [loadingPrice, setLoadingPrice] = useState(false)
    const [submittable, setSubmittable] = useState('')


    const [editLogo, setEditLogo] = useState(null);
    const [prologo, setPrologo] = useState(null);
	const [prologoUrl, setPrologoUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);

    
    // const [showPaywallModal, setShowPaywallModal] = useState(false)

    // const savePrice = () => {
    //     setLoadingPrice(true);
    //      // Set a 2-second delay before hiding the paywall modal
    //     setTimeout(() => {
    //         setShowPaywallModal(false);
    //         setLoadingPrice(false);
    //     }, 1500);
    // }

    
    // Handle input change for event details
    const handleEventChange = (e) => {
        const { name, value } = e.target;
        setEventDetails((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };

    const isEventValid = () => {
        return linktitle && description && date && time && eventLocation && organizer;
    };

    // Handle input change for ticket details
    const handleTicketChange = (e) => {
        const { name, value } = e.target;
        setTicket((prevTicket) => ({
        ...prevTicket,
        [name]: value,
        }));
    };

    const addTicket = () => {
        setTickets([...tickets, ticket]); // Add ticket to tickets array
        setTicket({ name: '', description: '', price: 0, currency: 'NGN' }); // Reset ticket form
    };

    const deleteTicket = (index) => {
        setTickets(tickets.filter((_, i) => i !== index));
    };

    const canSubmit = () => {
        return tickets.length > 0;  // Ensure at least one ticket is added before submitting
    };



    const hiddenFileInput = useRef(null);

	const handleonLogoClick = event => {
		hiddenFileInput.current.click();
	};

    const handleDeleteLogo = () => {
        setPrologo(null);
        setEditLogo(null);
        setPrologoUrl('');
    }

    const onChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
          const options = {
            maxSizeMB: 1, // Max size in MB
            maxWidthOrHeight: 1920, // Max width or height
            useWebWorker: true, // Use web workers for better performance
            onProgress: (progress) => {
              setProgress(Math.round(progress));
            },
          };
    
          try {
            const compressedBlob = await imageCompression(file, options);
    
            // Convert the compressed Blob back to a File object
            const compressedFile = new File(
              [compressedBlob],
              file.name,
              { type: compressedBlob.type }
            );
    
            setPrologo(compressedFile);
            setPrologoUrl(URL.createObjectURL(compressedFile));
            console.log(compressedFile);
            setProgress(0); // Reset progress
          } catch (error) {
            alert('Error compressing the image; please refresh the page and try again!');
            setProgress(0); // Reset progress on error
          }
        }
      };


    //   const onSubmit = e => {
    //     e.preventDefault();
    //     setLoading(true);
    //     // console.log(selectedOption.value);
    //     let formData = new FormData();
    //     if (prologo != null) {
    //       formData.append('thumb', prologo);
    //     } else if (editLogo == null) {
    //       formData.append('thumb', '');
    //     }
    //     formData.append('title', linktitle);
    //     // formData.append('creator_username', username);
    //     formData.append('url', url);
    //     formData.append('type', linkType);
    //     formData.append('price', price);
       
    
    //     addCreatorEvent(formData, setShowEvent, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setUrl);
    //   };

      const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
      
        // Create a FormData object to send the data as multipart form
        let formData = new FormData();
      
        // Step 1: Add Event-related fields
        formData.append('title', linktitle); // Event title
        formData.append('description', description); // Event description
        formData.append('date', date); // Event date
        formData.append('time', time); // Event time
        formData.append('location', eventLocation); // Event location
        formData.append('organizer', organizer); // Event organizer
        formData.append('join_link', joinLink); // Event join link
        
        if (prologo) {
          formData.append('image', prologo); // Event image (optional)
        }
      
        // Step 2: Add tickets
        // tickets.forEach((ticket, index) => {
        //   formData.append(`tickets[${index}][name]`, ticket.name);
        //   formData.append(`tickets[${index}][description]`, ticket.description);
        //   formData.append(`tickets[${index}][price]`, ticket.price);
        // //   formData.append(`tickets[${index}][currency]`, ticket.currency);
        // //   formData.append(`tickets[${index}][available]`, ticket.available ? 'true' : 'false');
        // });
        const ticketsData = JSON.stringify(tickets);
        formData.append('tickets', ticketsData);

        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

      
        // Step 3: Call the function to send FormData to the backend
        addCreatorEvent(formData, setShowEvent, setCreatorLinks, setLoading, setPrologo, setPrologoUrl, setLinktitle, setDescription, setEventLocation, setJoinLink);
      };
      



    return(
        <div className="">
          <TEModal show={showEvent} setShow={setShowEvent} scrollable className='!bg-white [&_*:not(button):not(input):not(textarea):not(select)]:!bg-white'>
            <TEModalDialog className='!p-3 !m-auto'>
                <TEModalContent>
                    {loadingPrice ? 
                        <div className="p-12 h-[300px]">
                            <div
                                className="flex m-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status">
                                <span
                                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                >Loading...</span>
                            </div> 
                        </div>
                        :
                        <>
                        <TEModalHeader>
                            {/* <!--Modal title--> */}
                            <h5 className="text-medium font-bold leading-normal text-neutral-800">
                            Event
                            </h5>
                            {/* <!--Close button--> */}
                            <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setShowEvent(false)}
                            aria-label="Close"
                            >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                            </button>
                        </TEModalHeader>
                        {/* <!--Modal body--> */}
                        <TEModalBody>   
                        <form className="" action="#" onSubmit={e => onSubmit(e)}>
                            {step === 1 && (
                                <>
                                    <div className="upload-logo">
                                        <div className="edit-pic">
                                            <div className='flex w-full flex-col'>
                                                <p className='mt-2 mb-2 !font-bold' style={{fontSize: '80%'}}>Select Poster</p>
                                                <img src={prologoUrl ? prologoUrl : editLogo} className="linkthumb !h-[250px] !w-[250px] !rounded-[20px]" />
                                            </div>
                                        </div>

                                        <div onClick={handleonLogoClick} className="selectbtn-img" style={{cursor: 'pointer'}}>
                                            <p>Select</p>
                                        </div>
                                        <div onClick={handleDeleteLogo} className="deletebtn-img" style={{cursor: 'pointer'}}>
                                            <p>Delete</p>
                                        </div>
                                    
                                        <input
                                            className='form-control'
                                            ref={hiddenFileInput}
                                            style={{display:'none'}}
                                            id="upload-profile"
                                            type='file'
                                            accept='image/png, image/jpeg, image/gif'
                                            onChange={(e) => onChange(e)}
                                        />
                                        </div>
                                    <div>
                                    
                                    <label htmlFor="email" className="!font-bold">
                                        Event Title
                                        </label>
                                        
                                        <div className="mt-2">
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            required
                                            value={linktitle}
                                            onChange={e => setLinktitle(e.target.value)}
                                            className=""
                                        />
                                        </div>
                                    </div>

                                    <div className="mb-4 bio-input">
                                        <label htmlFor="description" className="block text-gray-700 font-bold mb-2">Description</label>
                                        <textarea
                                            name="description"
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                            required
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        ></textarea>
                                    </div>

                                
                                    <div className="mb-4">
                                        <label htmlFor="date" className="block text-gray-700 font-bold mb-2">Date</label>
                                        <input
                                        type="date"
                                        name="date"
                                        value={date}
                                        onChange={e => setDate(e.target.value)}
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="time" className="block text-gray-700 font-bold mb-2">Time</label>
                                        <input
                                        type="time"
                                        name="time"
                                        value={time}
                                        onChange={e => setTime(e.target.value)}
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="location" className="block text-gray-700 font-bold mb-2">Location</label>
                                        <input
                                        type="text"
                                        name="location"
                                        value={eventLocation}
                                        onChange={e => setEventLocation(e.target.value)}
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="organizer" className="block text-gray-700 font-bold mb-2">Organizer</label>
                                        <input
                                        type="text"
                                        name="organizer"
                                        value={organizer}
                                        onChange={e => setOrganizer(e.target.value)}
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="joinLink" className="block text-gray-700 font-bold mb-2">Join Link</label>
                                        <input
                                        type="url"
                                        name="join_link"
                                        value={joinLink}
                                        onChange={e => setJoinLink(e.target.value)}
                                        
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    <div className={!isEventValid() ? 'submit-com-dis' : 'submit-comp'}>
                                        <button
                                            onClick={() => setStep(2)}
                                            disabled={!isEventValid()}
                                            className="complete-button"
                                        >
                                        Next: Add Tickets
                                        </button>
                                    </div>
                                
                                </>
                            ) }


                            {/* <p className="flex text-xs mt-2 text-[#6F7466]">This is a one time payment that your audience pay once to access this link.</p> */}
                                
                            {/* {price > 0 ? 
                                <div className='w-full flex justify-between items-center my-5 mb-8 p-4 py-5 rounded-2xl bg-[#F84F94]'>
                                    <p className='!text-base font-bold text-white'>NGN {price}</p>
                                    <img onClick={() => setPrice(0)} src={Xprice} className='w-[24px] cursor-pointer' />
                                </div> : <></>
                            } */}

                            {step === 2 && (
                                    <div>
                                    <h2 className="text-xl font-bold mb-4">Ticket Details</h2>

                                    {/* Ticket Details Inputs */}
                                    <div className="mb-4">
                                        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Ticket Name</label>
                                        <input
                                        type="text"
                                        name="name"
                                        value={ticket.name}
                                        onChange={handleTicketChange}
                                        
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    <div className="mb-4 bio-input">
                                        <label htmlFor="description" className="block text-gray-700 font-bold mb-2">Ticket Description</label>
                                        <textarea
                                        name="description"
                                        value={ticket.description}
                                        onChange={handleTicketChange}
                                        
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        ></textarea>
                                    </div>

                                    <div className="mb-4">
                                        <label htmlFor="price" className="block text-gray-700 font-bold mb-2">Ticket Price</label>
                                        <input
                                        type="number"
                                        name="price"
                                        value={ticket.price}
                                        onChange={handleTicketChange}
                                        
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>

                                    {/* <div className="mb-4">
                                        <label htmlFor="currency" className="block text-gray-700 font-bold mb-2">Currency</label>
                                        <select
                                        name="currency"
                                        value={ticket.currency}
                                        onChange={handleTicketChange}
                                        required
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        >
                                        <option value="NGN">NGN</option>
                                        <option value="USD">USD</option>
                                       
                                        </select>
                                    </div> */}

                                    <button
                                        type="button"
                                        onClick={addTicket}
                                        className="complete-button !ml-0 !mr-auto mb-7"
                                    >
                                        Add Ticket
                                    </button>

                                    {/* Display Added Tickets */}
                                    <div className="mt-4">
                                        <h3 className="text-lg font-bold">Added Tickets:</h3>
                                        {tickets.length > 0 ? (
                                        tickets.map((t, index) => (
                                            <div key={index} className="border border-[1px] border-[#D2D8C7] rounded-[10px] p-2 mb-2">
                                                <p><strong>{t.name}</strong> - {t.description} - {t.price} {t.currency}</p>
                                                {/* <button
                                                    type="button"
                                                    onClick={() => deleteTicket(index)}
                                                    className="text-red-500"
                                                >
                                                    Delete Ticket
                                                </button> */}
                                                <img src={Delete} onClick={() => deleteTicket(index)} className='flex cursor-pointer w-[15px] mt-2 mb-2 m-auto' />
                                            </div>
                                        ))
                                        ) : (
                                        <p>No tickets added yet</p>
                                        )}
                                    </div>

                                    <div className="mt-4 flex items-center">
                                        <button
                                            type="button"
                                            onClick={() => setStep(1)}
                                            className="complete-button"
                                            >
                                            Back: Edit Event Details
                                        </button>

                                        <div className={!canSubmit() ? 'submit-com-dis !mt-0 ml-2' : 'submit-comp !mt-0 ml-2'}>
                                            {loading ? 
                                                <div
                                                    className="flex ml-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                                    role="status">
                                                    <span
                                                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                                                    >Loading...</span>
                                                </div> :
                                                <button
                                                    type="submit"
                                                    disabled={!canSubmit() || loading}
                                                    className="complete-button"
                                                >
                                                Save Event
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    </div>
                                )}
                            

                                
                                
                            </form> 
                            

                            {/* <div className='submit-comp !w-full !ml-0 text-center'> 
                                <button
                                    onClick={savePrice}
                                    className="complete-button !justify-center !ml-0"
                                >
                                    Save
                                </button>
                            
                            </div> */}

                        </TEModalBody>
                        <TEModalFooter>
                            <TERipple rippleColor="light">
                            {/* <button
                                type="button"
                                className="inline-block rounded bg-primaryy-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primaryy-700 transition duration-150 ease-in-out hover:bg-primaryy-accent-100 focus:bg-primaryy-accent-100 focus:outline-none focus:ring-0 active:bg-primaryy-accent-200"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button> */}
                            </TERipple>
                            
                        </TEModalFooter>
                        </> 
                    }
                    
                </TEModalContent>
            </TEModalDialog>
            </TEModal>
        </div>
    )
}

export default TicketsModal;