import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { creatorlinks, loggedcreator, loggeduname, loggeddname, loggedcreatordata } from "../../state/atoms/Creator";
import { loadLinks, DeleteGiftBlock, deleteCreatorLinks, editCreatorLinks } from '../../state/actions/creator';

import AddBlockBtn from './addBlockBtn.tsx';

// import GiftBlockDemo from '../BioPage/Demos/giftblock.tsx';

import Reorder from '../../images/reorder.png';
import Edit from '../../images/newedit.png';
import Delete from '../../images/newtrash.png';

import Premium from '../../images/diamond_1.png';
// import Edit from '../../../images/edit.png';
import Plus from '../../../images/plus.png';
import Minus from '../../../images/minus.png';

import {
    TECollapse,
} from "tw-elements-react";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import DragIcon from '../../images/drag.png';




const DeleteLinkAsk = ({ open, setOpen, blockIndex, linkId, linkTitle, setCreatorLinks, loggedCreatorUname, setLoadingLDelete, loadingLDelete }) => {


    const deleteLink = () => {
        setLoadingLDelete(true);
        deleteCreatorLinks(linkId, setCreatorLinks, setOpen, setLoadingLDelete)
    }
  
    return (
      <Modal style={{borderRadius: '20px'}} open={open} onClose={() => setOpen(false)} center>    
            <div className='delete-section'>
                <h2 className='text-sm p-3 pb-0 text-center'>Do you want to delete this link: <span className='font-bold text-[grey]'>"{linkTitle}"</span>?</h2>
                {loadingLDelete ? 
                    <div
                        className="flex m-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span>
                    </div> : <button className='delete-btn !bg-[#121B00]' onClick={deleteLink}>Delete</button>
                }
                <button onClick={() => setOpen(false)}>Cancel</button>
            </div>
      </Modal>
    )
  }



const PageOrder = () => {
    const [linkButtons, setLinkButtons] = useState([]);

    let username = useRecoilValue(loggeduname);

    const [creatorLinks, setCreatorLinks] = useRecoilState(creatorlinks);
    const [loggedCreatorData, setLoggedCreatorData] = useRecoilState(loggedcreatordata);

    const [loggedCreator, setLoggedCreator] = useRecoilState(loggedcreator);
    const [loggedCreatorDname, setLoggedCreatorDname] = useRecoilState(loggeddname);
    const [loggedCreatorUname, setLoggedCreatorUname] = useRecoilState(loggeduname);

    const [giftBlockId, setGiftBlockId] = useState();

    const [linkId, setLinkId] = useState();
    const [linkTitle, setLinkTitle] = useState('');
    
    const [showModal, setShowModal] = useState(false);
    const [showGift, setShowGift] = useState(false)
    const [showGiftEditModal, setShowGiftEditModal] = useState(false);
    const [activeElement, setActiveElement] = useState("");

    const [loadingGSubmit, setLoadingGSubmit] = useState(false)
    const [loadingLDelete, setLoadingLDelete] = useState(false)

    const [open, setOpen] = useState(false);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        // Fetch existing link buttons
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 
        axios.get(`${process.env.REACT_APP_API_URL}/api/content/get-linkbuttons/`, config)
            .then(response => setLinkButtons(response.data))
            .catch(error => console.error('Error fetching link buttons:', error));
    }, []);

    useEffect(() => {
        if (loggedCreatorData.giftblock != null) {
          setGiftBlockId(loggedCreatorData.giftblock.id);
        }
        // console.log(loggedCreatorData.textblock.text);
      }, [loggedCreatorData]);

    const handleClick = (value: string) => {
        if (value === activeElement) {
          setActiveElement("");
        } else {
          setActiveElement(value);
        }  
    };


    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(creatorLinks);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);

        setCreatorLinks(reorderedItems);

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        }; 

        // Optionally send the new order to the backend
        axios.put(`${process.env.REACT_APP_API_URL}/api/content/linkbuttons/reorder/`, reorderedItems, config)
            // .then(response => loadLinks(setCreatorLinks))
            .catch(error => console.error('Error reordering link buttons:', error));
    };

    const activateDelete = (index, id, title) => {
        setDeleteModal(true)
        setCurrentIndex(index)
        setLinkId(id)
        setLinkTitle(title)
    }

    const deleteBlock = () => {
        setLoadingGSubmit(true);
        DeleteGiftBlock(giftBlockId, setLoadingGSubmit, setLoggedCreator, setLoggedCreatorData, setLoggedCreatorDname, setLoggedCreatorUname, setOpen)
    }

    

    return (
        <div className="link-button-manager">
            <AddBlockBtn showGift={showGift} setShowGift={setShowGift} showModal={showModal} setShowModal={setShowModal} />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="linkButtons">
                    {(provided) => (
                        <ul {...provided.droppableProps} ref={provided.innerRef}>
                            {creatorLinks.length > 0 ?
                            <>
                                {creatorLinks.map((button, index) => (
                                    <Draggable key={button.id} draggableId={String(button.id)} index={index}>
                                        {(provided) => (
                                            <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div className="link-button-main !flex-col">
                                                    <div className="flex !items-center !justify-between">
                                                        <img src={Reorder} />
                                                        <span>{button.title}</span>
                                                        <div className='edit-del w-[5%]'>
                                                            <img className='hidden' src={Edit} />
                                                            <img src={Delete} onClick={() => activateDelete(index, button.id, button.title)} />
                                                        </div>
                                                    </div>
                                                    {button.price > 0 ? <img className='w-[13px] mt-2 flex m-auto' src={Premium} /> : <></> }
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </> : <></> }
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>

            {loggedCreatorData.giftblock == null ? <></> : 
                <div className="link-button-main !items-center">
                    <img src={Reorder} className='opacity-[.4]' />
                    <span>Get Tipped</span>
                    <div className='edit-del'>
                        <img src={Edit} onClick={() => setShowGift(true)} />
                        <img src={Delete} onClick={() => setOpen(true)}/>
                    </div>
                </div>
            }

            <DeleteLinkAsk open={deleteModal} setOpen={setDeleteModal} blockIndex={currentIndex} linkId={linkId} linkTitle={linkTitle} setCreatorLinks={setCreatorLinks} loggedCreatorUname={loggedCreatorUname} setLoadingLDelete={setLoadingLDelete} loadingLDelete={loadingLDelete} />

            <Modal style={{borderRadius: '20px'}} open={open} onClose={() => setOpen(false)} center>    
                <div className='delete-section'>
                <h2 className='text-sm p-3 pb-0 text-center'>Do you want to delete your tipping link?</h2>
                {loadingGSubmit ? 
                    <div
                        className="flex m-auto h-8 w-8 mt-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span>
                    </div> : <button className='delete-btn !bg-[#121B00]' onClick={deleteBlock}>Delete</button>
                }
                <button onClick={() => setOpen(false)}>Cancel</button>
                </div>
            </Modal>
            
        </div>
    );
};

export default PageOrder;
