// import './signup.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
// import { authenticated } from "./Authenticated"
import { authenticated } from "../../state/atoms/Authenticated";
import { access } from "../../state/atoms/Authenticated";
import { refresh } from "../../state/atoms/Authenticated";
import { loggedcreator } from "../../state/atoms/Creator";
// import Home from '../containers/Home/home.tsx'
import {
  Link
} from "react-router-dom";
import { redirect, useNavigate  } from "react-router-dom";

import { signIn, handleGoogleLogin } from '../../state/actions/auth';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin } from '@react-oauth/google';

import Loginpic from '../../images/login-pic.png';
import Logo from '../../images/new_logo.png';
import LogoM from '../../images/logo-main.png';
import GoogleLogo from '../../images/google.png';
// import GoogleLogin from "react-google-signup";


function Login() {
  let navigate = useNavigate();

  const [authState, setAuthState] = useRecoilState(authenticated);
  const [accessState, setAccessState] = useRecoilState(access);
  const [refreshState, setRefreshState] = useRecoilState(refresh);
  const [loading, setLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false);

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [userNameAvailable, setUserNameAvailable] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)

  const isAuth = useRecoilValue(authenticated);
  const isCreatorAlready = useRecoilValue(loggedcreator);


  // useEffect(() => {
  //   // console.log(isAuth);
  //   // console.log(isCreatorAlready);
  // 	if (isAuth && isAuth != "loading") {
  //     if(isCreatorAlready && isCreatorAlready != 'loading') {
  //       return navigate('/me/appearance');
  //     } else if(!isCreatorAlready && isCreatorAlready != 'loading') {
  //       return navigate("/complete-profile");
  //     }
  // 	}
  // }, [isAuth, isCreatorAlready]);

  useEffect(() => {
    // Check if both isAuth and isCreatorAlready are not loading
    // if (isAuth !== 'loading' && isCreatorAlready !== 'loading') {

      
      // Once loading is complete, proceed with the appropriate navigation
      if (isAuth) {
        // if (isCreatorAlready) {
        //   return navigate('/me/appearance');  // Redirect to appearance if user is already a creator
        // } else {
        //   return navigate('/complete-profile');  // Redirect to complete profile if user is not a creator yet
        // }
        return navigate('/me/appearance');
      }
    // }
  }, [isAuth]);

  // useEffect(() => {
  // 	if (isCreatorAlready && isCreatorAlready !='loading') {
	// 		return navigate("/me/appearance/");
  // 	} else if (isAuth && !isCreatorAlready && isCreatorAlready != 'loading') {
  //     return navigate('/complete-profile');
  //   }
  // }, [isAuth, isCreatorAlready]);

  useEffect(() => {
  	// if (isAuth && isAuth !='loading') {
    //   if (isCreatorAlready && isCreatorAlready !='loading') {
    //     return navigate("/me/appearance/");
    //   } else {
    //     return navigate("/complete-profile");
    //   }
    // }
    console.log(isAuth)
    console.log(isCreatorAlready)
  }, [isCreatorAlready]);

  // useEffect(() => {
  // 	if (isCreatorAlready && isCreatorAlready !='loading') {
	// 		return navigate("/me/appearance/");
  // 	}
  // }, [isCreatorAlready]);


  const onEmailChange = e => setEmail(e.target.value );
  const onPWordChange = e => setPassword(e.target.value);

  const continueWithGoogle = async () => {
    setGoogleLoading(true);
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/me/appearance`)
        window.location.replace(res.data.authorization_url);   
    } catch (err) {

    }
  };


  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => handleGoogleLogin(codeResponse, setAuthState, setAccessState, setRefreshState),
    flow: 'implicit',
  });

  
  const onSubmit = e => {
    e.preventDefault();
    setLoading(true)
    // console.log('login');
    signIn(email, password, setAuthState, setAccessState, setRefreshState, setLoading);
  };

  return (
    <div className="signup">
      <div className="signup-section1">
        <img src={Loginpic} alt="Login to Prymshare"/>
      </div>

      <div className="signup-section2">
        <div className="flex justify-between items-center mt-3">
          <img className='logo hidden lg:flex' src={Logo} />
          <img className='logo flex lg:hidden' style={{width: '35px'}} src={LogoM} />
          <p className="bg-[#EEF1E8] text-[#505449] p-3 py-2 text-xs rounded-full">Not a Prymer? <Link to="/register" className='text-[#F84F94]'>Sign up</Link></p>
        </div>

        <div className="signup-texts">
          <h1 className='font-black text-[#121B00] tracking-tight'>WELCOME BACK</h1>
          <p className='text-xs text-[#6F7466]'>Login to your Prymshare account</p>
        </div>
        

        <form className="" action="#" onSubmit={e => onSubmit(e)}>
          <div>
            <div className="mt-4">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder='Email address'
                required
                value={email}
                onChange={e => onEmailChange(e)}
                className=""
              />
            </div>
          </div>

          <div>
            <div className="mt-4">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                placeholder='Password'
                value={password}
                onChange={e => onPWordChange(e)}
                minLength='6'
                required
                className=""
              />
            </div>

          </div>

          <div className='mt-9'>
            <button
              type="submit"
              className="flex w-full justify-center rounded-full bg-[#121B00] px-3 py-3 text-sm leading-6 text-white shadow-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00]"
            >
              {loading ? <div
                          className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status">
                          <span
                            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                          >Loading...</span>
                        </div> : <p>Log in</p>
                      }
            </button>
          </div>

          <Link to='/reset-password' className='flex text-[#F84F94] my-3 text-xs'>Forgot password?</Link>
        </form>

        <div className='flex justify-between items-center mt-10 '><hr className='w-5/12 text-[#D2D8C7]'/> or <hr className='w-5/12 text-[#D2D8C7]' /></div>

        <div className="google-btn" onClick={continueWithGoogle}>
          <img src={GoogleLogo} />
          <p>Continue with Google</p>
        </div>

      </div>
    </div>
  );
}

export default Login;
