
import ReactGA from "react-ga4";
import Footer from "../../containers/Home/footerlp.tsx";

function PrivacyPolicy() {
  return (
 <div>
       <div className="bg-[#F9FAF4]  px-[50px] sm:px-[100px] flex  pt-[150px] flex-col md:px-[312px] pb-20">
    <div className="pb-20">
        <h1 className="text-5xl md:text-8xl font-black">Prymshare Privacy Policy</h1>
        {/* <p className="font-bold pt-3 pb-20">Last Updated Date: October 9th, 2024</p> */}
    </div>

    <div className="pb-10">
       <h1 className="font-bold text-3xl">1. Introduction</h1> 
       <p className="font-extralight text-[#797E6F]
">
  Welcome to Prymshare! This Privacy Policy describes how we collect, use, and disclose your personal information when you use our web application.
</p>
    </div>

    <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">2. Information We Collect</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">
        We collect information you provide directly to us, such as your name, email address, and other information you choose to share.
       </p>

       <p className="font-extralight text-[#797E6F]">
       We may also automatically collect certain information when you use Prymshare, including your IP address, device information, and usage data.
       </p>
       </div>

       {/* <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">User Content</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">By submitting content to Prymshare, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the content in connection with Prymshare's services. 
            
       </p>

       <p className="font-extralight text-[#797E6F]">
       You retain ownership of any intellectual property rights in the content you submit.
       </p>
       </div> */}


       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">3. How We Use Your Information</h1>
        <div className="font-extralight pb-10 text-[#797E6F] gap-5 flex flex-col">
        <p className="pb-5">
        We use the information we collect to:
        </p>
        <ol>
            <li>Provide and improve our services</li>
            <li>Send you updates and notifications</li>
            <li>Personalize your experience on Prymshare</li>
           <li>Respond to your inquiries and provide support</li>
       </ol>
        </div>
    
       </div>

       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">4. Sharing of Your Information</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">
        We may share your personal information with third parties only in the ways that are described in this Privacy Policy.
       </p>
       <p className="font-extralight text-[#797E6F]">
       We will not sell, rent, or share your personal information with third parties for their promotional purposes.
       </p>
     </div>



     <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">5. Your Choices</h1>
        <p className="font-extralight pb-10 text-[#797E6F]">
        You may choose not to provide certain personal information, but this may limit your ability to use certain features of Prymshare.
       </p>
       <p className="font-extralight text-[#797E6F]">
       You can update your account information and preferences at any time.
       </p>
    
       </div>

       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">6. Security</h1>
   
       <p className="font-extralight text-[#797E6F]">
       We take reasonable measures to help protect your personal information from loss, theft, misuse, and unauthorized access.
       </p>
    
       </div>


       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">7. Changes to this Privacy Policy</h1>
   
       <p className="font-extralight text-[#797E6F]">
       We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
       </p>
    
       </div>

       <div className="pb-10">
        <h1 className="font-bold  text-3xl pb-3">8. Contact Us</h1>
   
       <p className="font-extralight text-[#797E6F]">
       If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@prymshare.co">support@prymshare.co</a>.
       </p>
    
       </div>
   </div>
   <Footer/>
 </div>
  );
}

export default PrivacyPolicy;


// function PrivacyPolicy() {
//   return (
//     <main className="px-6 py-24 sm:py-32 lg:px-8">
//         <header>
//         <h1>Prymshare Privacy Policy</h1>
//     </header>

//     <section>
//         <h2>1. Introduction</h2>
//         <p>Welcome to Prymshare! This Privacy Policy describes how we collect, use, and disclose your personal information when
//             you use our web application.</p>
//     </section>

//     <section>
//         <h2>2. Information We Collect</h2>
//         <p></p>
//         <p></p>
//     </section>

//     <section>
//         <h2>3. How We Use Your Information</h2>
//         <p>We use the information we collect to:</p>
//  
//     </section>






//     <section>
//         <h2>7. Changes to this Privacy Policy</h2>
//         <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
//             Policy on this page.</p>
//     </section>

//     <section>
//         <h2>8. Contact Us</h2>
//         <p></p>
//     </section>
//     </main>
//   );
// }

// export default PrivacyPolicy;