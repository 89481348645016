import { useEffect, useState } from 'react';
import {Link, useNavigate} from "react-router-dom";

import axios from 'axios';
import Axios from 'axios';

import Logo from '../../images/logo-white.png';
import LogoMain from '../../images/logo-main.png';
import LogoNew from '../../images/new-logo-p.png';
import Twitter from '../../images/x-twitter.png';
import Linkedin from '../../images/linkedin.png';
import Facebook from '../../images/facebooklp.png';
import Telegram from '../../images/telegram.png';
import DiscordWhite from '../../images/discord-white.png';
import Instagram from '../../images/iglp.png';
import Arrow from '../../images/Arrow.png';
import SignArrow from '../../images/circular-arrow.png';

import ReactGA from "react-ga4";


function Footer () {

  let navigate = useNavigate();
  const [username, setUsername] = useState('')

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('Join Waitlist');

  const join = (e) => {
    // e.preventDefault();
    // console.log('waiting');
    navigate('/register');
    // if(email == '') {
    //   setError("Please enter a valid email!");
    // } else if(email.includes('.com') == false) {
    //   setError("Please enter a valid email!");
    // } else {
    //   joinList();
    // }
  }

  const joinList = () => {
      // e.preventDefault();
      setLoading(true);
      setSuccess('....');
      Axios({
          url: `${process.env.REACT_APP_API_URL}/api/profile/join-waitlist/`,
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
          },
        data: {
            email: email,
        }
      }).then(response => {
        setError('');
        setSuccess("Congratulations!");
        setLoading(false)
        setEmail('')
        let data = response.data
        if (data['error'] === false) {
          //console.log('Error There. What did you do wrong now??')
        }
      }).catch(error => {
        //console.log(error);
      })

    }
    
    const handleClick = () => {
      ReactGA.event({
        category: 'User',
        action: 'Clicked My Button',
        label: 'Button Click',
      });
      navigate('/register');
    }

  return (
    <div className="flex flex-col bg-[#121B00] w-full pt-5" id="joinwaitlist">
      <div className='flex flex-col  p-4 pt-14 lg:pt-20 pb-16 w-full'>
        <div className='flex flex-col'>
          <div className=''><h1 className='text-white text-4xl lg:text-6xl font-black text-center'>A SPACE FOR YOU!</h1></div>
          <div className='flex flex-col p-3 w-full max-w-lg justify-center m-auto lg:justify-between lg:max-w-xl lg:flex-row lg:items-center lg:mt-4 lg:pt-3 box-border'>
            <div className="flex px-4 py-2 justify-between items-center font-bold lg:mr-3 rounded-full  bg-white text-base lg:text-xl lg:px-4 lg:py-2 lg:w-full lg:mt-0">
                <div className='flex items-center'>
                  <p className="text-neutral-400">
                    prymshare.co/
                  </p>
                  <p><input className="m-0 p-0 w-full border-0 font-bold placeholder:text-neutral-400 outline-0 outline-none"
                    type='text' name='username' value={username} placeholder="username" onChange={(e) => setUsername(e.target.value)}
                  /></p>
                </div>
                <div onClick={handleClick} className='p-2 rounded-full lg:p-2 bg-primaryy-500 cursor-pointer'>
                  <img src={SignArrow} className='block w-5 lg:w-7' alt='Sign up button' />
                </div>
            </div>
          </div>
    </div>
        <div className='mt-12 p-4 pt-16 lg:p-16 lg:pt-20 lg:pb-20 max-w-6xl m-auto rounded-2xl flex flex-col lg:flex-col bg-[#F7F9F4] w-full'>
          <div className='flex flex-col lg:flex-row justify-between items-start pb-8'>
            <div className='text-[#121B00] flex flex-col mb-5'>
              <h2 className='font-black text-lg text-[#121B00] mb-3'>COMPANY</h2>
              <Link className='text-sm text-[#121B00] block mb-2' to='mailto:prymshare.info@gmail.com'>Contact Us</Link>
              <Link className='text-sm text-[#121B00] block mb-2' to='/terms-conditions'>Terms and Conditions</Link>
              <Link className='text-sm text-[#121B00] block mb-2' to='/privacy-policy'>Privacy Policy</Link>
              <Link className='text-sm text-[#121B00] block mb-2' to='/refund-policy'>Refund Policy</Link>
            </div>
            <div className='text-[#121B00] flex flex-col mb-5'>
              <h2 className='font-black text-lg text-[#121B00] mb-3'>FEATURES</h2>
              <a className='text-sm text-[#121B00] block mb-2' href='#linkinbio'>Link in Bio</a>
              <a className='text-sm text-[#121B00] block mb-2' href='#virtual'>Virtual Event Link</a>
            </div>
            <div className='text-[#121B00] lg:flex flex flex-col lg:items-end lg:w-5/12 pr-5'>
              <p className='text-sm text-[#121B00] block mb-2'>Prym Technologies Ltd.</p>
              <p className='text-sm text-[#121B00] block mb-2'>Parakin, Obalufe Stile, Ife.</p>
              <p className='text-sm text-[#121B00] block mb-2'>Osun State, Nigeria. </p>
              <p className='text-sm text-[#121B00] block mb-2'>prymshare.info@gmail.com</p>

              <div className='flex w-5/12 lg:w-4/12 justify-between mt-6'>
                <a className='mr-2 lg:w-12/12' href='https://instagram.com/prymshare'><img src={Instagram} alt='Instagram Handle' /></a>
                <a className='mr-2 lg:w-12/12' href='https://twitter.com/prymshare'><img src={Twitter} alt='Twitter(X) handle'/></a>
                <a className='mr-2 lg:w-12/12' href='https://facebook.com/prymshare'><img src={Facebook} alt='Facebook Handle' /></a>
              </div>
            </div>
          </div>

          <div className='flex flex-col lg:flex-row justify-center lg:justify-between items-center mt-5'>
            <div className='flex justify-between items-center w-full lg:w-4/12 mb-6 lg:mb-0'>
              <Link to='/login' className='bg-[#fff] flex items-center w-4/12 m-auto text-[#262626] text-xs text-center justify-center border-2 border-[#E5E5E5] hover:text-black hover:bg-white hover:border-[#121B00] px-7 py-3 rounded-full'>Login</Link>
              <Link to='/register' className='bg-primaryy-600 items-center flex w-7/12 m-auto text-neutral-900 text-xs text-center justify-center hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00] px-7 py-3.5 rounded-full'>Get started - It's free</Link>
            </div>
            
            <a href="https://t.me/+KmBdfSNVdkw3ZTNk" className='flex w-full lg:w-3/12 justify-center items-center px-6 py-3 lg:mt-0 lg:px-2 lg:py-3 mb-12 lg:mb-0 text-xs font-medium text-white bg-[#4EA4F6] rounded-full'>
              <img className='' style={{width: '25px'}} src={Telegram} alt='Prym Discord' />
              <p className='text-white text-xs mr-1 ml-3'>Join our Telegram community</p>
            </a>
          </div>
          
        </div>

        <div className='flex flex-col items-center justify-center mt-7 lg:mt-10 mb-10' >
          <img className='w-5/12 h-auto object-contain lg:w-2/12 p-4' src={LogoNew} />
          <p className='text-center text-xs font-light text-neutral-400 mt-4 mb-4'>&copy; 2024, Prym Technologies Ltd.</p>
          <p className='text-center text-xs font-light text-neutral-400'>All trademarks and brand names belong to their respective owners. Use of these trademarks and brand names do not<br className='hidden lg:block' /> represent endorsement by or association with Prymshare.</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
